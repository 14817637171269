<script setup lang="ts">
import type { GoogleMapMarkerModel } from '@mop/ui/types/googleMap';
import { googleMapMarkerModel } from '@mop/ui/models/googleMapMarkerModel';
import pinFilled from '@mop/shared/images/misc/pin-filled.svg?url';

type ShowRoom = {
  _uid: string;
  title: string;
  longitude: string;
  latitude: string;
  streetAndNumber?: string;
  zipAndCity?: string;
  phone?: string;
  contactPerson?: string;
  email?: string;
  image?: {
    filename?: string;
  };
};

defineOptions({
  name: 'ShowRoom',
});

const { $gtm, $storyblokLivePreview } = useNuxtApp();
const { getCmsStory, trackPageView, cmsStoryModelRef, loadingRef: loadingCms } = useMopCms();
const { initTransition } = useMopPageTransitionClient();
const { handleHeadForCmsPage } = useMopSeo();
const classNameRef = ref(['']);
initTransition(classNameRef, loadingCms);
const showRoomMarkersRef = ref<GoogleMapMarkerModel[]>([]);
const mapLoadedRef = ref(false);
const selectedMarkerRef = ref('');
const googleApiKey = useRuntimeConfig().public.GOOGLE_API_KEY;

useAsyncData(async () => {
  await getCmsStory('/company/facts-figures');
  trackPageView('Showroom');
});

watch(
  [mapLoadedRef, () => loadingCms.value.loading],
  () => {
    if (mapLoadedRef.value && !loadingCms.value.loading) {
      showRoomMarkersRef.value = cmsStoryModelRef.value.getAttribute('showrooms').map(mapShowroomToMarker);
    }
  },
  { immediate: true },
);

function mapShowroomToMarker(showRoom: ShowRoom) {
  return googleMapMarkerModel({
    id: showRoom._uid,
    title: showRoom.title,
    icon: pinFilled,
    geoLocation: {
      longitude: Number(showRoom.longitude),
      latitude: Number(showRoom.latitude),
    },
    popupContent: getPopupContent(showRoom),
    country: showRoom.zipAndCity,
  });
}

function getPopupContent(showRoom: ShowRoom) {
  const infoParts: string[] = [];
  const image: string = showRoom?.image?.filename
    ? `
          <div class="showroom-popup__img-wrap">
            <img class="showroom-popup__img" src="${showRoom.image.filename}" />
          </div>`
    : '';
  if (showRoom.title) {
    infoParts.push(`<div class="showroom-popup__headline">${showRoom.title}</div>`);
  }
  if (showRoom.streetAndNumber) {
    infoParts.push(`<p>${showRoom.streetAndNumber}</p>`);
  }
  if (showRoom.zipAndCity) {
    infoParts.push(`<p class="showroom-popup__country">${showRoom.zipAndCity}</p>`);
  }
  if (showRoom.email && showRoom.contactPerson) {
    infoParts.push(
      `<p><a class="showroom-popup__email" href="mailto:${showRoom.email}">${showRoom.contactPerson}</a></p>`,
    );
  }
  if (showRoom.phone) {
    infoParts.push(`<p><a class="showroom-popup__phone" href="tel:${showRoom.phone}">${showRoom.phone}</a></p>`);
  }

  return `
        <div class="showroom-popup">
          <div class="showroom-popup__info">
            ${infoParts.join(' ')}
          </div>
          ${image}
        </div>
      `;
}

function handleMapLoaded() {
  mapLoadedRef.value = true;
}

function handleMarkerSelect(mopMarker: GoogleMapMarkerModel) {
  selectedMarkerRef.value = mopMarker.getId();
  $gtm.trackEvent({
    eventName: 'showroom_locater',
    parameters: ['click', mopMarker.getTitle(), mopMarker.getCountry()],
  });
}

$storyblokLivePreview.initStoryListener('Storyblok', cmsStoryModelRef);

const seoHeadRef = computed(() => {
  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return {};
  }
  return handleHeadForCmsPage(cmsStoryModelRef.value);
});

useHead(seoHeadRef);
</script>

<template>
  <div :class="['showroom', 'transition', ...classNameRef]">
    <MopCmsContentElementsSlot
      id="showroom__content"
      class="showroom__content"
      :elements="cmsStoryModelRef.getContentElements('body')"
    />

    <div class="showroom__map-wrapper">
      <UiGoogleMap
        :api-key="googleApiKey"
        :region="$mopI18n.lang"
        :language="$mopI18n.lang"
        :markers="showRoomMarkersRef"
        :selected-marker-id="selectedMarkerRef"
        force-render
        class="showroom__map"
        @map-initialized="handleMapLoaded"
        @select-marker="handleMarkerSelect"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.showroom__map-wrapper {
  display: flex;
  justify-content: center;
  padding: 0 $global-padding;
}

.showroom__map {
  height: 660px;
  max-width: 1000px;
  width: 100%;
}
</style>
