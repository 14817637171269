import type { FunctionalComponent } from 'vue';
import type { Coordinates } from '@mop/ui/types/googleMap';

export function googleMapMarkerModel(responseData: any) {
  return {
    getId(): string {
      return responseData?.id;
    },

    getTitle(): string {
      return responseData?.title || '';
    },

    getCountry(): string {
      return responseData?.country;
    },

    getCoordinates(): Coordinates {
      return responseData?.geoLocation;
    },

    getIcon(): FunctionalComponent {
      return responseData.icon ?? '';
    },

    getSelectedIcon(): string {
      return responseData.selectedIcon ?? responseData.icon;
    },

    getPopupContent(): string {
      return responseData.popupContent ?? '';
    },

    getOrigin(): unknown {
      return responseData.originModel;
    },
  };
}
